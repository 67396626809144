import React from "react";

export const projects = [
  {
    name: "bidNimble",
    tags: [
      "Sveltekit",
      "Node/Express",
      "MongoDB",
      "Tailwind CSS",
      "bcrypt",
      "jwt",
      "Svelte-Carousel, Svelte-Scrollto",
      "Mdsvex",
      "Vercel",
      "Cloudinary",
      "Heroku",
    ],
    link: "https://bid-nimble.vercel.app/",
    frontendRepo: "https://github.com/craig-mcgowan/bid_nimble_frontend",
    backendRepo: "https://github.com/craig-mcgowan/bidnimble-api",
    image:
      "https://res.cloudinary.com/dm9ig1vtk/image/upload/v1644182873/Portfolio/2022-02-06_16.27.49_vw7ztd.png",
    vid: "https://res.cloudinary.com/dm9ig1vtk/video/upload/v1644183317/Portfolio/bidNimble_recording_ydihxy.mov",
    description:
      "bidNimble is a construction estimating web application with a frontend built with Sveltekit and a Node/Express backend. It demonstrates the ability to create read update and delete multiple models, fluid and intuitive layout and styling and the ability to learn and experiment with new technologies.",
  },
  {
    name: "monikr",
    tags: [
      "Ruby",
      "Rails",
      "React",
      "React Router",
      "Tailwind CSS",
      "SQL",
      "postgresql",
      "bcrypt",
      "faker",
    ],
    link: "https://monikr.vercel.app/",
    frontendRepo: "https://github.com/craig-mcgowan/monikrfront",
    backendRepo: "https://github.com/craig-mcgowan/monikr_api",
    image:
      "https://res.cloudinary.com/dm9ig1vtk/image/upload/v1644183630/Portfolio/2022-02-06_16.40.20_hbnldt.png",
    vid: "https://res.cloudinary.com/dm9ig1vtk/video/upload/v1644183860/Portfolio/monikr_recording_at_4.41.29_PM_ez9lxy.mov",
    description: "A baby name browsing and saving app.",
  },
  {
    name: "Vidster",
    tags: ["React", "React Router", "Node", "Express", "MongoDB", "Group"],
    link: "https://mystifying-hermann-6ecd7b.netlify.app/",
    frontendRepo: "https://github.com/dflores23/playlist-frontend",
    backendRepo: "https://github.com/dflores23/playlist-backend",
    image:
      "https://res.cloudinary.com/dm9ig1vtk/image/upload/v1644185217/Portfolio/2022-02-06_17.06.48_fpgqpf.png",
    vid: "https://res.cloudinary.com/dm9ig1vtk/video/upload/v1644185389/Portfolio/vidster_recording_gxu9jf.mov",
    description: "A group project showcasing team git workflows, React and Express",
  },
  {
    name: "BuildHub",
    tags: [
      "Javascript",
      "HTML",
      "CSS",
      "ExpressJS",
      "EJS",
      "HTMX",
      "NodeJS",
      "SASS",
      "Morgan",
      "BCrypt",
      "Trello",
    ],
    link: "https://craigmcgowan-project2.herokuapp.com/",
    frontendRepo: "https://github.com/craig-mcgowan/ringo-project2",
    backendRepo: null,
    image:
      "https://res.cloudinary.com/dm9ig1vtk/image/upload/v1644184416/Portfolio/137223630-997c7f8f-7e34-4e36-b28d-55d54def132c_liwspc.png",
    vid: "https://res.cloudinary.com/dm9ig1vtk/video/upload/v1644184617/Portfolio/BuildHub_Recording_dyk95t.mov",
    description: "A construction project tracking app",
  },
  {
    name: "Computer Science Trivia",
    tags: [
      "Contentful",
      "Vercel",
      "HTML",
      "CSS",
      "JavaScript",
      "AJAX",
      "Bootstrap",
      "Google Fonts",
    ],
    link: "https://seir-project-1-trivia-game-craig-mcgowan.vercel.app/",
    frontendRepo: "https://github.com/craig-mcgowan/seir-project-1",
    backendRepo: null,
    image:
      "https://res.cloudinary.com/dm9ig1vtk/image/upload/v1644184854/Portfolio/2022-02-06_17.00.43_ptqxvb.png",
    vid: "https://res.cloudinary.com/dm9ig1vtk/video/upload/v1644184982/Portfolio/Trivia_Recording_gmtjqr.mov",
    description: "A computer science trivia app",
  },
];